const Browser = {
  get isAndroid() {
    return /(android)/i.test(window.navigator.userAgent)
  },

  get isMobile() {
    return !!window.estately.data.is_mobile
  },

  get isSafari() {
    return /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent)
  },
}

export default Browser
