import $ from 'legacy/vendor/jquery.custom'

import ComponentLoader from 'legacy/lib/ComponentLoader'
import EstatelyEvents  from 'legacy/lib/EstatelyEvents'

import CardCarousel from 'legacy/views/carousels/CardCarousel'

export default class ListingCardCarousel extends ComponentLoader {
  static initialize() {
    $('.js-listing-card-carousel').one(EstatelyEvents.AJAX_CONTENT.LOADED, this.onCarouselFetched)
  }

  static onCarouselFetched = (event) => {
    event.currentTarget
      .querySelectorAll('.js-card-carousel')
      .forEach((carousel) => {
        CardCarousel.bind(carousel)
        this.onClickToListingCard(carousel)
      })
  }

  static onClickToListingCard = (carousel) => {
    $(carousel).find('.listing-card').on('click', (event) => {
      window.location.href = event.currentTarget.dataset.path
    })
  }
}
