import $ from 'legacy/vendor/jquery.custom'


export function lastClosestIndex(collection, target) {
  return collection.lastIndexOf(collection.reduce((bestFit, x) => (
    Math.abs(x - target) < Math.abs(bestFit - target) ? x : bestFit
  )))
}
export function firstClosestIndex(collection, target) {
  return collection.indexOf(collection.reduce((bestFit, x) => (
    Math.abs(x - target) < Math.abs(bestFit - target) ? x : bestFit
  )))
}

export default class CarouselScroller {
  static smoothScrollSpeed = 400

  static EVENTS = {
    ITEM_CHANGE:        'change.lastItemInViewport',
    PAGE_CHANGE:        'change.currentPage',
    TOTAL_PAGES_CHANGE: 'change.totalPages',
  }

  scroller  = null // DOM Element
  $scroller = null // jQuery Element

  lastItemInViewport  = 0
  firstItemInViewport = 0
  currentPage         = 0
  itemEndPoints       = [] // Coordinates for the end of each list item
  itemStartPoints     = [] // Coordinates for the beginning of each list item
  pageStartPoints     = [] // Coordinates for the start of each “page”
  maxScrollLeft       = 0  // The max we can scroll to (without “rubber-banding”)
  scrollerWidth       = 0

  // TODO: Remove this when browser support improves
  // See https://caniuse.com/#feat=mdn-api_scrolltooptions_behavior
  nativeSmoothingSupported = ('scrollBehavior' in document.documentElement.style);

  get totalPages() { return this.pageStartPoints.length }

  constructor(el) {
    // Set up data
    this.$scroller  = $(el).find('.js-carousel-scroller')
    this.scroller   = this.$scroller.get(0)
    this.$buttons   = $(el).find('.carousel-button')
    this.totalItems = this.scroller.children.length

    this.updateMetrics()


    // Attach Events

    $(window).on('resize', this.onResize)

    this.$scroller
      .on('scroll', this.onScroll)
      .on(CarouselScroller.EVENTS.PAGE_CHANGE,        () => this.renderButtonStates())
      .on(CarouselScroller.EVENTS.TOTAL_PAGES_CHANGE, () => this.renderButtons())

    if (!this.nativeSmoothingSupported) {
      ['touchstart', 'pointerdown', 'wheel'].forEach((event) => {
        this.scroller.addEventListener(event, this.cancelAnimation, { passive: true })
      })
    }

    this.$buttons.on('click', this.onButtonClick)


    // Render

    this.renderButtons()
  }

  next = ({ smooth = true } = {}) => this.move({ direction:  1, smooth })
  prev = ({ smooth = true } = {}) => this.move({ direction: -1, smooth })

  scrollToPage(i, { smooth = true } = {}) {
    const target = this.pageStartPoints[i]
    this.scrollTo(target, { smooth })
  }


  // ---------------------------------------------------------------------
  // Private methods
  // ---------------------------------------------------------------------

  onButtonClick = ({ currentTarget: button }) => {
    // call this.next() / .prev()
    this[button.dataset.direction]()
  }

  onResize = () => {
    requestAnimationFrame(() => this.updateMetrics())
  }

  onScroll = () => {
    requestAnimationFrame(() => this.updateClosestItemAndPage())
  }

  renderButtons() {
    requestAnimationFrame(() => {
      this.$buttons.toggleClass('hidden', this.totalPages <= 1)
      this.renderButtonStates()
    })
  }

  renderButtonStates() {
    requestAnimationFrame(() => {
      const [prev, next] = this.$buttons.get()

      prev.disabled = this.currentPage <= 0
      next.disabled = this.currentPage >= this.totalPages - 1
    })
  }

  cancelAnimation = () => {
    this.$scroller.stop()
  }

  updateClosestItemAndPage() {
    const scrollStart = this.scroller.scrollLeft
    const scrollEnd   = scrollStart + this.scrollerWidth
    const closestPage = lastClosestIndex(this.pageStartPoints, scrollStart)
    const closestItem = lastClosestIndex(this.itemEndPoints, scrollEnd)
    const firstItem   = firstClosestIndex(this.itemStartPoints, scrollStart)

    if (closestPage !== this.currentPage) {
      this.currentPage = closestPage
      this.$scroller.trigger(CarouselScroller.EVENTS.PAGE_CHANGE, {
        currentPage: this.currentPage,
      })
    }

    if (closestItem !== this.lastItemInViewport) {
      this.lastItemInViewport = closestItem
      this.$scroller.trigger(CarouselScroller.EVENTS.ITEM_CHANGE, {
        lastItemInViewport: this.lastItemInViewport,
      })
    }

    if (firstItem !== this.firstItemInViewport) {
      this.firstItemInViewport = firstItem
    }
  }

  updateMetrics() {
    const prevTotalPages = this.totalPages

    // Reset
    this.itemEndPoints   = []
    this.itemStartPoints = []
    this.pageStartPoints = []

    // Record Metrics
    this.scrollerWidth = this.scroller.clientWidth
    this.maxScrollLeft = this.scroller.scrollWidth - this.scrollerWidth


    // Recalculate start/end points for items and pages
    // ------------------------------------------------

    const children = [...this.scroller.children]

    // NOTE: We have to be careful here, to reduce reflow/layout thrashing
    // See: https://gist.github.com/paulirish/5d52fb081b3570c81e3a
    children.forEach((item) => {
      const start        = item.offsetLeft
      const end          = start + item.offsetWidth
      const hasSnapPoint = !window.getComputedStyle(item).scrollSnapAlign?.endsWith('none')

      if (hasSnapPoint && start < this.maxScrollLeft) {
        this.pageStartPoints.push(start)
      }

      this.itemEndPoints.push(end)
      this.itemStartPoints.push(start)
    })

    this.pageStartPoints.push(this.maxScrollLeft)

    // ------------------------------------------------

    if (this.totalPages !== prevTotalPages) {
      this.$scroller.trigger(CarouselScroller.EVENTS.TOTAL_PAGES_CHANGE)
    }

    this.updateClosestItemAndPage()
  }

  // direction = 1 (next) or -1 (prev)
  move({ direction = 1, smooth = true }) {
    let nextPage = this.currentPage + direction

    // Looping
    if (nextPage < 0)                nextPage = this.totalPages - 1
    if (nextPage >= this.totalPages) nextPage = 0

    this.scrollToPage(nextPage, { smooth })
  }

  scrollTo(x, { smooth = true } = {}) {
    const scrollLeft = Math.min(x, this.maxScrollLeft)

    if (this.nativeSmoothingSupported || !smooth) {
      this.scroller.scrollTo({ left: scrollLeft, behavior: smooth ? 'smooth' : 'auto' })
      return
    }

    this.cancelAnimation()
    this.$scroller.animate({ scrollLeft }, CarouselScroller.smoothScrollSpeed)
  }
}
