import $ from 'legacy/vendor/jquery.custom'

import CarouselScroller from 'legacy/views/carousels/CarouselScroller'

export default class CardCarousel {
  static bind(selector) {
    return new CardCarousel(selector)
  }

  constructor(el) {
    this.$carousel = $(el)

    if (!this.$carousel.length) return

    this.$dots    = this.$carousel.find('.carousel-dots')
    this.scroller = new CarouselScroller(this.$carousel)

    this.$carousel
      .on(CarouselScroller.EVENTS.PAGE_CHANGE,        () => this.renderActiveDot())
      .on(CarouselScroller.EVENTS.TOTAL_PAGES_CHANGE, () => this.renderDots())
      .on('click', '.dot', this.onDotClick)

    this.renderDots()
  }

  onDotClick = ({ currentTarget: dot }) => {
    this.scroller.scrollToPage($(dot).parent().index())
  }

  onPageChange = () => {
    this.renderActiveDot()
  }

  renderDots() {
    requestAnimationFrame(() => {
      const totalDots = this.scroller.totalPages
      this.$dots.toggleClass('hidden', totalDots <= 1)

      if (totalDots <= 1) return

      this.$dots.empty()

      for (let i = 0; i < totalDots; i += 1) {
        this.$dots.append(
          `<li>
            <button class="dot" type="button">
              <span class="sr-only">Go to slide ${i + 1}</span>
            </button>
          </li>`,
        )
      }

      this.renderActiveDot()
    })
  }

  renderActiveDot() {
    requestAnimationFrame(() => {
      const previouslyActive = this.$dots.find('.dot.active')
      previouslyActive.removeClass('active')
      previouslyActive.removeAttr('aria-current')

      const currentlyActive = this.$dots.find(`.dot:eq(${this.scroller.currentPage})`)
      currentlyActive.addClass('active')
      currentlyActive.attr('aria-current', 'true')

      this.updateAccessibilityAttributes()
    })
  }

  updateAccessibilityAttributes = () => {
    const carouselScroller = this.$carousel.find('.js-carousel-scroller')[0]
    const liItems = Array.from(carouselScroller.querySelectorAll('li'))

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const isIntersecting = entry.isIntersecting
        this.updateAriaHidden(entry.target, isIntersecting)
        this.updateTabIndex(entry.target, isIntersecting)
      })
    }, { threshold: 0.5 })

    liItems.forEach((item) => {
      observer.observe(item)
    })
  }

  updateAriaHidden = (target, isIntersecting) => {
    $(target).attr('aria-hidden', !isIntersecting)
  }

  updateTabIndex = (target, isIntersecting) => {
    const focusableElements = target.querySelectorAll(`
      a[href],
      button:not([disabled]),
      input:not([disabled]),
      select:not([disabled]),
      textarea:not([disabled]),
      [tabindex]:not([disabled]):not([tabindex="-1"])
    `)

    focusableElements.forEach((element) => {
      $(element).attr('tabindex', isIntersecting ? '0' : '-1')
    })
  }
}
